import React,{Fragment} from 'react'

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link } from 'react-router-dom';
import bgimage from '../assets/images/bg-img/1.png';

const EarlyYears = () =>{
    return(
        <Fragment>
            <Header />
                <div className="pageheader-section style-2 mb-40" style={{paddingBottom:100,backgroundImage: `url('/assets/images/bg-img/1.png')`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                    <div className="container" style={{backgroundColor: 'rgb(255 255 255 / 65%)',padding:10}}>
                        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse"></div>
                            <div className='col-sm-12 mb-10'>
                                <center>
                                <img src='/assets/images/logo/Early-Years-Logo.png' style={{width:400}} />
                                </center>
                                <h2>Early Year International Program</h2> 
                                <p>The concept of HIDAYA International Schools Early Years Program is inspired by elephant herds, is a beautiful and powerful one for an early years program. It centres around these key themes:</p>     
                                <ol>
                                    <li><b>Nurturing and Protection: </b>Just as elephant mothers and aunts form a protective circle around their young, the program provides a safe and supportive environment where children feel secure and loved.  This allows them to explore and learn without fear.</li>
                                    <li><b>Community and Collaboration: </b> Elephant herds demonstrate the strength of community.  The program fosters a sense of belonging and encourages collaboration among children, teachers, and parents.  Children learn from each other and support one another's growth.</li>
                                    <li><b>Guidance and Mentorship: </b> Older elephants guide the younger ones, passing down knowledge and skills.  Similarly, experienced teachers and educators act as mentors, nurturing children's curiosity and guiding them on their learning journey.</li>
                                    <li><b>Holistic Development:  </b> Elephants teach their young essential life skills, from foraging to social interaction. The program takes a holistic approach to development, focusing not just on academics but also on social-emotional growth, creativity, and physical well-being.</li>
                                    <li><b>Strength in Unity:  </b> The elephant herd's ability to overcome challenges together inspires the program to foster a strong partnership between the school, parents, and the community.  Working together ensures the best possible outcomes for the children.</li>
                                    <li><b>Growth and Resilience: </b> Young elephants learn to be resilient and adapt to their environment. The program aims to instil these qualities in children, preparing them to face challenges with confidence and bounce back from setbacks.</li>
                                </ol>
                                <p>
                                    This concept, rooted in the natural behaviour of elephants, provides a compelling metaphor for the nurturing and supportive environment a high-quality early years program should offer. It emphasizes the importance of community, guidance, and holistic development in shaping young minds.
                                </p>
                            </div>
                        </div>
                </div>
            <Footer />
        </Fragment>
        
    )
}

export default EarlyYears