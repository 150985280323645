import React,{Fragment,useEffect ,useState} from 'react'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import ClipLoader from "react-spinners/ClipLoader";

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import baseURL from '../api/index';
import InputMask from 'react-input-mask';

const EnrollForm = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const [basicModal, setBasicModal] = useState(false);

  
  const [Ccountry , setCcountry] = useState([]);
  const [getcCountryID , setGetcCountryID] = useState(129);
  const [allInquiryData , setAllInquiryData] = useState({cCountryId : getcCountryID })
  
  const [Cprovince , setCprovince] = useState([])
  const [getCurProvinceID,setGetCurProvinceID] = useState('')

  const [curCities , setCurCities] = useState([])

  // permanant address states
  const [perCountry, setPerCountry] = useState([])
  const [perCountryID , setPerCountryID] = useState('')

  const [perProvince , setPerProvince] = useState([])
  const [perProvinceID, setPerProvinceID] = useState('')

  const [perCity , setPerCity] = useState([])


  const [getreligon,setReligon] = useState([])
  const [montherTonges,setMotherTonge] = useState([])
  const [grades , setGrade] = useState([])
  const [sessions,setSessions] = useState([])
  const [references,setReference] = useState([])
  const [isSuccesMsg ,setIsSuccessMsg] = useState('')
  const [isSubmited, setIsSubmited] = useState(false);
  const [campuses ,setCampuses]  = useState([])


  const HandleCountry = async(e) =>{
    const getCountryId = e.target.value
    setGetcCountryID(getCountryId)
    setAllInquiryData({...allInquiryData , [e.target.name]: e.target.value})
       
  }

  const HandlePerCountry = async(e) =>{
    const getPerCountryId = e.target.value
    setPerCountryID(getPerCountryId)
    setAllInquiryData({...allInquiryData , [e.target.name]: e.target.value})
  }

  const HandleCurProvince = async(e) =>{
    const getCurProvinceID = e.target.value
    setGetCurProvinceID(getCurProvinceID)
    setAllInquiryData({...allInquiryData , [e.target.name]: e.target.value})
  }

  const HandlePerProvince = async(e) =>{
    const getPerProvinceID = e.target.value
    setPerProvinceID(getPerProvinceID)
    setAllInquiryData({...allInquiryData , [e.target.name]: e.target.value})
  }

  const [emailerr ,setEmailErr] = useState(false)
  console.log(emailerr)
  const HandleOnChangeValues = async(e) =>{
    e.preventDefault(e)
    console.log('e:',e)
    // let value = ''
    // if(e.target.name === 'firstName'){
    //   value = e.target.value.toUpperCase() + text.slice(1)
    // }else{
    //   value = e.target.value
    // }
    
    const value = e.target.value
    setAllInquiryData({...allInquiryData , [e.target.name]: value})
    console.log('al inquiries data:',allInquiryData)
    if(e.target.name === 'email'){
      const value = e.target.value
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(reg.test(value)){
            
            setEmailErr(false)
            console.log('value')
        }else{
            setEmailErr(true)
        }
    }
  }
  const HandleCampus = async(e,id) =>{
    e.preventDefault()
    console.log("E",e,',id',id)
    setAllInquiryData({...allInquiryData , 'campusId': id})
  }

  const [saveAllData , setSaveAllData]=useState('')
  
  const [loader , setLoader] = useState(false)

  const HandleDataSubmit = async(e) =>{
      e.preventDefault(e)
      //setAllInquiryData({...allInquiryData , [e.target.name]: e.target.value})
      setLoader(true)
      ///const {cCityId,cCountryId,cHouseNo,cProvinceId,cStreetNo,cTown,contactNumber,dateOfBirth,familyName,fatherName,firstName,gradeId,middleName,montherTongeId,pCityId,pCountryId,pHouseNo,pProvinceId,pStreetNo,pTown,referenceId,religionId} = allInquiryData;
      const allData = {
        
      }
      console.log('get submit data:',allInquiryData)
      const res = await axios.post(`${baseURL}/api/inquiry/addinquiry`,allInquiryData)
      const data = res.data
      console.log(data)
      if(data.status === true){
        console.log(data.message)
        setLoader(false)
        setSaveAllData(data.data)
        setIsSuccessMsg(data.message)
        setBasicModal(true)
        setIsSubmited(true)
        document.getElementById("create-course-form").reset();
      }else{
        console.log(data.message)
      }
  }

  const navigate = useNavigate()
  

  console.log('allInquiryData',allInquiryData)

  useEffect(() => {
    if(saveAllData){
        const timer = setTimeout(() => {
            navigate('/')
        }, 3000);
        return () => clearTimeout(timer);
    }
    // const GetCurContry = () =>{
    //   setAllInquiryData({...allInquiryData , cCountryId : getcCountryID })
    // }
    // GetCurContry()
    
  }, [saveAllData]);
  

  useEffect(()=>{
    const getCountry = async() =>{
      const res = await axios.get(`${baseURL}/api/address/getcountries`)
      const data = await res.data
      setCcountry(data.data)
    }
    getCountry()

    const getPerCountry = async() =>{
      const res = await axios.get(`${baseURL}/api/address/getcountries`)
      const data = await res.data
      setPerCountry(data.data)
    }
    getPerCountry()

    const getProvince = async() =>{
      const res = await axios.get(`${baseURL}/api/address/getprovincebycountry?countryid=${getcCountryID}`)
      const data = res.data
      setCprovince(data.data)
    }
    getProvince()

    const getPerProvince = async() =>{
      const res = await axios.get(`${baseURL}/api/address/getprovincebycountry?countryid=${perCountryID}`)
      const data = res.data
      setPerProvince(data.data)
    };

    getPerProvince();

    const getCurCities = async(e) =>{
      const res = await axios.get(`${baseURL}/api/address/getcitybyprovince?provinceId=${getCurProvinceID}`)
      const data = res.data
      setCurCities(data.data)
    };
     

    const getPerCities = async(e) =>{
      const res = await axios.get(`${baseURL}/api/address/getcitybyprovince?provinceId=${perProvinceID}`)
      const data = res.data
      setPerCity(data.data)
    }

    getPerCities()

    const getCampus = async() =>{
      const body ={
        pageNo: 1,
        pageSize: 100,
        isEnabled : true
      }

      const {data} = await axios.post(`${baseURL}/api/campus/getall` , body)
      console.log(' Campus ' , data.data)
      setCampuses(data.data)
    }
    getCampus()

    const getReligon = async() =>{
      const res = await axios.get(`${baseURL}/api/religion/getreligions`)
      const data = res.data
      setReligon(data.data)
    }

    const getMotherTongs = async() =>{
      const res = await axios.get(`${baseURL}/api/tonge/getmothertonges`)
      const data = res.data
      setMotherTonge(data.data)
    }

    const getSessions = async() =>{
      const res = await axios.get(`${baseURL}/api/session/getsessions`)
      const data = res.data
      setSessions(data.data)
      const sessionYearId = data.data.slice(-1)[0]
      setAllInquiryData({...allInquiryData , sessionId: sessionYearId?.id})
    }
    getSessions()

    const getGrades = async() =>{
      const res = await axios.get(`${baseURL}/api/grade/getgrades`)
      const data = res.data
      setGrade(data.data)
    }
    getGrades()

    const getRefrence = async () =>{
      const res = await axios.get(`${baseURL}/api/reference/getreferences`)
      const data = res.data
      setReference(data.data)
    }
    getRefrence()

    getMotherTongs()

    getReligon()

    getCurCities()
  },[getCurProvinceID,getcCountryID , perCountryID,perProvinceID])
  return (
    <Fragment>
            
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                    <MDBModalTitle><h2 className='text-success'>Congratulations!</h2></MDBModalTitle>
                    
                    </MDBModalHeader>
                    <MDBModalBody>
                        <h5>Your application has been successfully submitted.</h5>
                        
                    </MDBModalBody>
                </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
          <div className="pt-5 style-2 mb-20 bg-primary" style={{backgroundImage: `url('/assets/images/bg-img/pexels-johny-rebel-the-explorer-panda-114076-357271.jpg')`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            <div className="container" style={{backgroundColor:'rgb(8 8 8 / 76%)'}}>
                <div className="flex flex-col items-start bg-gray-900 text-cyan-300 p-6 rounded-lg w-80">
                    <p className="text-lg mb-4">3 ➜ The gender of Abdul Hayee is</p>
                    <div className="w-full">
                    {campuses?.map(item =>(
                        <>
                        <button
                            className={`flex items-center w-full p-3 mb-2 rounded-md border border-cyan-300 text-left w-75 ${
                                allInquiryData?.campusId === item.id ? "bg-cyan-700 bg-success" : "bg-transparent"
                            }`}
                            key={item.id}
                            name='campusId'
                            value={item.id}
                            onClick={(e) => HandleCampus(e,item.id)}
                        >
                            <span className="mr-2 h5" style={{color:'#ffe35a'}}>{item.name}</span>
                            {allInquiryData?.campusId === item.id && <span style={{marginLeft:5,fontSize:22}}>✔</span>}
                            <br/>
                            <small
                                className={`${
                                    allInquiryData?.campusId === item.id ? "text-white" : "text-white"
                                }`}
                            > {item.address}</small>
                        </button>
                        <br/>
                        </>
                    ))}
                    </div>
                    <button className="mt-4 bg-yellow-500 text-black px-6 py-2 rounded-md font-bold">OK</button>
                </div>
                  {loader ? 
                    <div className="row">
                      <center>
                                <MDBModal show={true} tabIndex='-1'>
                                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                        <ClipLoader
                                            marginTop={300}
                                            color={'#fff'}
                                            loading={loader}
                                            size={70}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                </MDBModal>
                      </center>
                    </div>
                  : null }
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    {isSubmited ? 
                      <h4 className='text-success'><center>{isSuccesMsg}</center></h4>
                    : null}
                    <center>
                      <p style={{fontSize:16}}><strong>Note:</strong><span style={{color:'red'}}> * </span> Please fill out the form below and our Admissions Office will contact you and provide the information you desire.</p>
                    </center>
                    <form onSubmit={HandleDataSubmit} id="create-course-form" className='form'>
                      <h5 className='mt-4'>Select Campus</h5>
                      
                      <div className='row'>
                        <input type='hidden'  name='inquiryNo' value='0' />
                        
                        <div className='col-md-12'>
                            
                            <div className='form-group'>
                                <select className='form-control' name='campusId' required onChange={HandleOnChangeValues}>
                                  <option value=''>-select campus-</option>        
                                  {campuses?.map(item =>(
                                    <option key={item.id} value={item.id}>{item.name}, {item.cityName}</option>
                                  ))}            
                                </select>
                            </div>
                        </div>

                        <h5 className='mt-4'>Information</h5>


                        <div className='col-md-6 mt-3'>
                            <label style={{fontWeight: 550}}>Session <span className='text-danger'>*</span></label>
                            <div className='form-group'>
                                <select  
                                  value={allInquiryData?.sessionId}
                                  className='form-control' 
                                  name='sessionId' 
                                  required 
                                  onChange={HandleOnChangeValues}
                                  disabled={true}
                                >
                                  <option value=''>-select-</option>        
                                  {sessions.map((session,index)=>(
                                      <option key={index} value={session.id}>{session.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                            <label className='text-center' style={{fontWeight: 550}}>Grade <span className='text-danger'>*</span></label>
                            <div className='form-group'>
                                <select className='form-control' name='gradeId' required onChange={HandleOnChangeValues}>
                                  <option value=''>-select-</option>
                                    {grades.map((grade,index)=>(
                                      <option key={index} value={grade.id} >{grade.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        
                        <div className='col-md-6 mt-3'>
                          <label style={{fontWeight: 550}}>First Name: <span className='text-danger'>*</span> </label>
                          <div className='form-group'>
                            <input required className='form-control'  name='firstName' onChange={HandleOnChangeValues} placeholder='Enter First Name' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label style={{fontWeight: 550}}>Middle Name: </label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} name='middleName'  placeholder='Enter Middle Name' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Last Name: <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} required name='familyName'  placeholder='Enter Last Name' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Date of Birth: <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} required type='date' name='dateOfBirth'  placeholder='Date of Birth' />
                          </div>
                        </div>

                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Father Name: <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} type='text' required name='fatherName'  placeholder='Enter Father Name' />
                          </div>
                        </div>

                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Father Email: 
                            <span className='text-danger'>
                              * {emailerr? <>Please Enter Valid Email</> : null}
                                    
                            </span>
                          </label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} type='email' required name='email'  placeholder='Enter Father Email' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Father CNIC: <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <InputMask mask="99999-9999999-9" name='cnic' onChange={HandleOnChangeValues} className='form-control' placeholder="XXXXX-XXXXXXX-X" required />
                          </div>
                        </div>  


                        <div className='col-md-6 mt-3 mb-5'>
                          <label className='label' style={{fontWeight: 550}}>Whatsapp No: <span className='text-danger'>*</span> </label>
                          <div className='form-group'>
                            <InputMask mask="+99999-9999999" className='form-control' onChange={HandleOnChangeValues} name='contactNumber' required placeholder='+92321-1234567' />
                          </div>
                        </div>

                        
                        <hr/>
                        <h5 className='mb-4'>Address Information</h5>
                        <div className='col-md-6 mt-3'>
                          <label style={{fontWeight: 550}}>Country <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <select value={allInquiryData?.cCountryId} onChange={HandleCountry} required className='form-control' name='cCountryId'>
                              <option>-select-</option>
                              {Ccountry?.map((cCountryData,index)=>(
                                <option key={index} value={cCountryData.id}
                                
                                >
                                  {cCountryData.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label style={{fontWeight: 550}}>State <span className='text-danger'>*</span> </label>
                          <div className='form-group'>
                            <select onChange={HandleCurProvince} required className='form-control' name='cProvinceId'>
                              <option>-select-</option>
                              {Cprovince.map((cProvinceData,index) =>(
                                <option key={index} value={cProvinceData.id}>{cProvinceData.name}</option>
                              ))}
                                
                            </select>
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label style={{fontWeight: 550}}>City <span className='text-danger'>*</span> </label>
                          <div className='form-group'>
                            <select className='form-control' name='cCityId' required onChange={HandleOnChangeValues}>
                              <option>-select-</option>
                              {curCities.map((curCityData,index) =>(
                                <option key={index} value={curCityData.id}>
                                  {curCityData.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>House No <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} required name='cHouseNo'  placeholder='House no' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='label' style={{fontWeight: 550}}>Street No <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} required name='cStreetNo'  placeholder='Street  No' />
                          </div>
                        </div>
                        <div className='col-md-6 mt-3 mb-5'>
                          <label className='label' style={{fontWeight: 550}}>Town <span className='text-danger'>*</span></label>
                          <div className='form-group'>
                            <input className='form-control' onChange={HandleOnChangeValues} required name='cTown'  placeholder='Town' />
                          </div>
                        </div>
                        
                        <hr />
                        <div className='col-md-2'></div>
                        <div className='col-md-8'>
                          <h5>Reference</h5>
                            <label className='label' style={{fontWeight: 550}}>How did you hear about us?<span className='text-danger'>*</span></label>
                            <div className='form-group'>
                              <select name='referenceId' onChange={HandleOnChangeValues} required className='form-control'>
                                <option value=''>-select-</option>
                                {references.map((refr , index) =>(
                                  <option key={index} value={refr.id}>{refr.name}</option>
                                ))}
                              </select>
                            </div>
                        </div>
                        <div className='col-md-2'></div>
                        <div className='col-md-12 my-5 '>
                          <center>
                            <button className='btn btn-success' disabled={emailerr ? true : false}> <i className='icofont-save'></i> Save</button>
                          </center>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
          </div>

    </Fragment>
    
  )
}

export default EnrollForm