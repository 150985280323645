import { Component, Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import PaymentDetailsForm from "../component/sidebar/payment-details-form";
import CartContext from "../context/cart-context";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GiConfirmed } from "react-icons/gi";

const CheckOutPage = () => {
  const { cartSubTotal, cartItems, clearCart } = useContext(CartContext);
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({});
  const [studentData, setStudentData] = useState({});

  const [studentID, setStudentID] = useState();
  const [showProceedToCheckout, setShowProceedToCheckout] = useState(false);
  const [invalidStudentID, setInvalidStudentID] = useState(false);

  const [address1, setAddress1] = useState("");
  const [firstName1, setFirstName1] = useState("");
  const [familyName1, setFamilyName1] = useState("");
  const [contactNumber1, setContactNumber1] = useState("");
  const [email1, setEmail1] = useState("");
  const [id1, setId1] = useState("");
  const modalStyles = {
    textAlign: "center",
    marginTop: "4rem",
    boxShadow: "none", // Remove the box shadow
    outline: "none", // Remove the outline
    padding: "20px",
  };

  const headerStyles = {
    fontSize: "34px",
    marginBottom: "40px",
    top: "0",
  };

  const buttonStyles = {
    border: "none",
    background: "#f16126",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    display: "block",
    textAlign: "center",
  };

  const confirmedIconStyles = {
    color: "green", // Set the color to green
    fontSize: "40px", // Adjust the font size if needed
    marginBottom: "10px", // Adjust spacing as desired
  };

  const handleStudentIDSubmit = async () => {
    try {
      const response = await fetch(
        `https://api.hintschool.org/api/admission/getadmissionbystudentnumber?studentNumber=${studentID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setShowProceedToCheckout(true);
        setInvalidStudentID(false);

        const student = await response.json();
        // console.log(student); // Log the fetched student data

        const {
          cCity,
          cCountry,
          cHouseNo,
          cProvince,
          cStreetNo,
          cTown,
          firstName,
          familyName,
          contactNumber,
          email,
          id,
        } = student.data;

        const address = `${cHouseNo}, ${cStreetNo}, ${cTown}, ${cCity}, ${cProvince}, ${cCountry}`;
        setAddress1(address);
        setFirstName1(firstName);
        setFamilyName1(familyName);
        setEmail1(email);
        setContactNumber1(contactNumber);
        setId1(id);
        console.log("Address:", address);
        console.log("First Name:", firstName);
        console.log("Family Name:", familyName);
        console.log("Contact Number:", contactNumber);
        console.log("Email:", email);
        console.log("ID:", id);
      } else {
        setShowProceedToCheckout(false);
        setInvalidStudentID(true);
      }
    } catch (error) {
      console.error("There was a problem with the GET request:", error);
    }
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
    console.log("data on check out:", data);
  };

  const handleConfirmOrder = () => {
    const combinedData = {
      formData,
      cartSubTotal,
      cartItems,
      studentID,
    };

    console.log("cart items from order confirm:", cartItems);

    const orderDetails = cartItems.map((item) => ({
      productId: item.product.data.id,
      amount: item.product.data.sizes[0].price,
      discount: item.product.data.sizes[0].discount,
      qty: item.quantity,
      netAmount: item.price * item.quantity, // Calculating net amount based on price and quantity
      size: item.product.data.sizes[0].name,
    }));

    const grandTotal = orderDetails.reduce(
      (total, item) => total + item.netAmount,
      0
    );

    const totalDiscount = orderDetails.reduce(
      (total, item) => total + item.discount,
      0
    );

    const totalNetAmount = orderDetails.reduce(
      (total, item) => total + item.netAmount,
      0
    );

    console.log(formData);

    const extractedFormData = {
      orderNumber: "string",
      admissionId: id1,
      contactNumber: formData.phone,
      grandTotal: grandTotal - totalDiscount, // Subtract totalDiscount from grandTotal
      discount: totalDiscount,
      netAmount: totalNetAmount,
      orderStatusId: "1",
      couponCode: formData.couponCode,
      address: formData.address,
      trackingNumber: "",
      referenceNumber: "",
      orderDetails,
    };

    console.log("Order Details:", extractedFormData);

    const baseUrl = process.env.REACT_APP_HINT_ORDERS_URL;
    const endpoint = "/add";
    const url = baseUrl + endpoint;

    const postData = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if required by your API
          },
          body: JSON.stringify(extractedFormData), // Convert object to JSON string
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("POST request successful. Response:", data);
        setShowModal(true); // Show the modal after successful order placement
      } catch (error) {
        console.error("There was a problem with the POST request:", error);
        // Handle error
      }
    };

    postData();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    clearCart();
  };

  return (
    <Fragment>
      <Header />

      <div className="pageheader-section style-2 mb-10">
        <div className="shop-cart padding-tb">
          <div className="container">
            <div className="section-wrapper">
              <div className="cart-bottom">
                <div className="shiping-box">
                  <div className="row">
                    <div className="col-md-7 col-12">
                      <h3>Checkout</h3>
                      <form
                        className="coupon"
                        onSubmit={(e) => e.preventDefault()}
                        style={{ marginTop: "30px" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <h6 style={{ color: "#f16126" }}>
                            Enter Student Roll Number to continue
                          </h6>
                        </div>

                        <input
                          type="text"
                          name="coupon"
                          placeholder="Roll Number..."
                          className="cart-page-input-text"
                          value={studentID}
                          onChange={(e) => setStudentID(e.target.value)}
                          style={{ marginBottom: "20px" }}
                        />
                        <button
                          type="submit"
                          onClick={handleStudentIDSubmit}
                          className="btn btn-success w-100"
                        >
                          Enter Student Roll Number
                        </button>
                      </form>

                      {showProceedToCheckout ? (
                        <>
                          {" "}
                          {" "}
                          <div className="calculate-shiping">
                            <PaymentDetailsForm
                              onSubmit={handleFormSubmit}
                              address={address1}
                              firstName={firstName1}
                              familyName={familyName1}
                              email={email1}
                              contactNumber={contactNumber1}
                            />
                          </div>
                        </>
                      ) : invalidStudentID ? (
                        <div>
                          <h5
                            style={{
                              color: "#f16126",
                              marginTop: "20px",

                              textAlign: "center",
                            }}
                          >
                            {" "}
                            Wrong/Invalid Student ID entered.
                          </h5>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-5 col-12">
                      <div className="cart-overview">
                        <h3>Cart Totals</h3>
                        <ul className="lab-ul">
                          <li>
                            <span className="pull-left">Cart Subtotal</span>
                            <p className="pull-right">
                              {cartSubTotal + ".00 PKR"}
                            </p>
                          </li>
                          <li>
                            <span className="pull-left">
                              Shipping and Handling
                            </span>
                            <p className="pull-right">To be calculated</p>
                          </li>
                          <hr className="divider-line my-5" />

                          <li>
                            <h5 className="pull-left">Total amount</h5>
                            <h3 className="pull-right">
                              {" "}
                              {cartSubTotal + ".00 PKR"}
                            </h3>
                          </li>
                          
                        </ul>
                        {showProceedToCheckout ? 
                            <button
                              className="lab-btn w-100 mt-3"
                              onClick={handleConfirmOrder}
                            >
                              <span>Confirm Order</span>
                            </button>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={showModal} onHide={handleCloseModal}>
        <div style={modalStyles}>
          <h1 style={headerStyles}>
            Order Confirmed <GiConfirmed style={confirmedIconStyles} />
          </h1>
          <Link to="/" style={buttonStyles} onClick={handleCloseModal}>
            Close
          </Link>
        </div>
      </Modal>
    </Fragment>
  );
};

export default CheckOutPage;

