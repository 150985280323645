import React,{Fragment} from "react";
import FacebookPageEmbed from "./facebook";
import InstagramProfileEmbed from "./instagram";

const AllSocialMedia=()=>{
    return(
        <Fragment>
        <div className="about-section style-2 section-bg">
            <div className="container">
                <div className="row justify-content-center">
                <div className="section-header text-center">
                        <h2 className="title title-h2">HINT SOCIAL MEDIA</h2>
                    </div>
                </div>
                <div className="row justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                    
                    <FacebookPageEmbed />
                    <InstagramProfileEmbed />
                </div>
            </div>
        </div>
        </Fragment>
    )
}

export default AllSocialMedia;