import React, { Component, Fragment, useEffect, useState, Suspense } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

import AchievementTwo from "../component/section/achievement-2";
//import BannerTwo from "../component/section/banner-2";
import Blog from "../component/section/blog";


import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import { Link,NavLink } from "react-router-dom";
import { Button } from "bootstrap";
import FeatureTwo from '../component/section/feature-2'
import FacebookPageEmbed from "../component/socialmedia/facebook";
import AllSocialMedia from "../component/socialmedia";
//import CategoryTwo from "../component/section/category-2";
//import AboutTwo from "../component/section/about-2";
//import CourseTwo from "../component/section/course-2";

const BannerTwo = React.lazy(()=> import("../component/section/banner-2"));
const AboutTwo = React.lazy(() => import("../component/section/about-2"));
const CourseTwo = React.lazy(() => import("../component/section/course-2"));
const CategoryTwo = React.lazy(() => import("../component/section/category-2"));
const InstructorTwo = React.lazy(() => import("../component/section/instructor-2"));

const HomeTwo = () => {
    
    const [bgcolor ,setBGColor] = useState('#2f4776')
    const [color , setColor] = useState('#fff')
    const [isset ,setIsset] = useState(true)
    const [icon,setIcon]  = useState('icofont-ui-calendar')

    useEffect(()=>{
        const ColorChange = () =>{
            const timer = setTimeout(() => {
                if(isset === true){
                    setBGColor('#2f4776')
                    setColor('#fff')
                    setIcon('icofont-ui-calendar')
                    setIsset(false) 
                }else{
                    setBGColor('F60F6E')
                    setColor('#EAF40F')
                    setIcon('icofont-check-circled')
                    setIsset(true)  
                }
                
            }, 1000);
            return () => clearTimeout(timer);
        }
        ColorChange()
    },[isset])

    return (
        <>
        <Fragment>
            <Header/>
            <Suspense>
                <BannerTwo />
            </Suspense>

            <Suspense>
                <AboutTwo />
            </Suspense>

            <Suspense>
                <InstructorTwo/>
            </Suspense>

            <Suspense>
                <CourseTwo />
            </Suspense>
            <Suspense>
                <FeatureTwo />
            </Suspense>
            <Suspense>
                <CategoryTwo />
            </Suspense>
            <Suspense>
                <AllSocialMedia />
            </Suspense>
            <Footer />
            

        </Fragment>
            {/* <a href="/inqueryform" target="_blank"  */}
            <NavLink 
                className='d-block d-md-block d-lg-none d-xl-none'
                    style={{
                        position: 'fixed',
                        top: '229px',
                        right: '4px',
                        backgroundColor:'#C08E2D',
                        padding: 10,
                        fontSize: 17,
                        fontWeight: 600,
                        color: '#fff',
                        borderRadius: 3
                    }} 
                    to={'/inqueryform'}
                >
                     <span>ENROLL STUDENT</span> 
            </NavLink>
            {/* <NavLink 
                    style={{
                        position: 'fixed',
                        top: '250px',
                        right: '20px',
                        backgroundColor:bgcolor,
                        padding: 10,
                        fontSize: 17,
                        fontWeight: 600,
                        color: color,
                        borderRadius: 3
                    }} 
                    to={'/appointment'}
                >
                    <i class={icon}></i> <span>APPOINTMENT</span> 
            </NavLink> */}
        </>
    );
}
 
export default HomeTwo;