import { useState,useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./assets/icofont.min.css"

import ScrollToTop from "./ScrollToTop";
const phoneNumber = "+92-33-HIDAYA-22";
const address = "57 L Block Model Town Lahore, Pakistan";



let socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/hintschoolsofficial',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/hidaya-international-school/',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/hintschoolsofficial/',
    },
    {
        iconName: 'icofont-whatsapp',
        siteLink: 'https://wa.me/message/R7E6Z6AMNZLSG1',
    },
    {
        iconName: 'icofont-tiktok',
        siteLink: 'https://www.tiktok.com/@hint.schools?_t=ZS-8tO3mRAn6Rk&_r=1',
    }
]

const Header = () => {


    const [isLogin , setIsLogin ] =useState(false)
    const [openAdmision,setOpenAdmision] = useState(false)
 	const [openAssociate,setOpenAssociate] = useState(false)

    useEffect(()=>{
        let Login = localStorage.getItem('isUserLogin')
        if(Login){
            setIsLogin(true)
        }else if(Login === null || Login === ''){
            setIsLogin(false)
        }
        setOpenAdmision(false)
        setOpenAssociate(false)
    },[])

    
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

    
    const handleAdmission = (e) =>{
        e.preventDefault()
        console.log('admsiion', openAdmision)
        if(openAdmision){
            setOpenAdmision(false)
        }else{
            setOpenAdmision(true)
        }
    }
    const handleAssociate = (e) =>{
        e.preventDefault()
         if(openAssociate){
            setOpenAssociate(false)
        }else{
            setOpenAssociate(true)
        }
    }

    window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    return (
        <>
            <ScrollToTop />
            
            <header className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
                <div className={`header-top ${socialToggle ? "open" : ""}`}>
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="lab-ul left " style={{color: '#050202',fontWeight: 600}}>
                                <li><i className="icofont-ui-call"></i> <span>{phoneNumber}</span></li>
                                <li><i className="icofont-location-pin"></i> {address} </li>
                            </ul>
                            <ul className="lab-ul social-icons d-flex align-items-center" >
                                <li><p style={{color: '#050202',fontWeight: 600}}>Find us on : </p></li>
                                {socialList.map((val, i) => (
                                    <li key={i}><a href={val.siteLink} target="_blank"><i className={val.iconName}></i></a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom" style={{backgroundColor:'#fff9f1'}}>
                    <div className="container">
                        <div className="header-wrapper" style={{padding: '9px 0 !important'}}>
                            <div className="logo">
                                <NavLink to="/"><img src="/assets/images/logo/Logo-Title-Page.png" alt="logo" style={{width:'210px'}} /></NavLink>
                            </div>
                            <div className="menu-area">
                                <div className="menu">
                                    <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                        <li>
                                            <NavLink to="/">Home</NavLink>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a onClick={handleAdmission} href="#" role="button" className={`${openAdmision ? "show" : ""}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded={openAdmision} data-bs-offset="0,0" >Admission</a>
                                            
                                            <ul className={`lab-ul dropdown-menu ${openAdmision ? "show" : ""}`}>
                                           
                                                <li><NavLink to="/admissionprocedure">Admission Procedure</NavLink></li>
                                                <li><NavLink to={'/inqueryform'} >Enroll Student</NavLink></li>
                                                <li><NavLink to={'/uniform'}>Uniform</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to={'/academics'}>Academics</NavLink></li>
                                        <li><NavLink to="/campuses-network">Campuses</NavLink></li>
                                        <li className="menu-item-has-children">
                                            <a onClick={handleAssociate} href="#" role="button" className={`${openAssociate ? "show":""}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="openAssociate" data-bs-offset="0,0">Associate</a>
                                            <ul className={`lab-ul dropdown-menu ${openAssociate ? "show":""}`}>
                                                <li><NavLink to="/associateLetter">Associate Letter</NavLink></li>
                                                <li><NavLink to="/associatemodel">Associate Model</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><Link to={'/accreditations'}>Accreditations</Link></li>
                                        <li><Link to={'/careers'}>Careers</Link></li>
                                        <li><Link to={'/eshop'}>Shop</Link></li>
                                        
                                                
                                    </ul>
                                </div>
                                <Link className="login d-none d-md-none d-lg-block d-xl-block" style={{background:'rgb(192, 142, 45)',color:'#fff',fontWeight:'bold'}} to={'/inqueryform'}> <span>ENROLL STUDENT</span></Link>
                                {isLogin ? 
                                    <Link className="login" to={'/logout'}><i className="icofont-user"></i> <span>LOG OUT</span></Link>
                                : 
                                    <Link to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>
                                }
                                
                          

                                <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
            
            
        </>
    );
}
 
export default Header;